
import { mapState, mapActions } from "vuex";
import {
  Hero,
  Banner,
  Teaser,
  OrderCard,
  OfferCard,
  PageSection,
  TopSellerCard,
  RewardsSection,
} from "@/components";

export default {
  name: "HomePage",
  components: {
    Hero,
    Banner,
    Teaser,
    OrderCard,
    OfferCard,
    PageSection,
    TopSellerCard,
    RewardsSection,
  },
  computed: {
    ...mapState(["user", "orderHistory", "rewards", "location"]),
    showOrderAgain() {
      return this.user && this.orderHistory?.length > 0;
    },
    showLoggedTopSellers() {
      return this.user && this.orderHistory?.length === 0;
    },
    showTopSellers() {
      return !this.user;
    },
    showYourOffers() {
      return this.user && this.rewards && this.rewards.length > 0;
    },
    showRewards() {
      return !this.user;
    },
    topSellers() {
      const section = this.getContent("top-sellers");
      if (section && section.products) {
        return section.products;
      }
      return []
    },
    ourStory() {
      return this.getContent('our-story')
    },
    comunitySupport() {
      return this.getContent('comunity-support')
    },
    locationBanner() {
      return this.getContent('location-banner')
    },
    yourOffers() {
      // Storyblok rewards
      const storyblok = this.getContent("offers-section");
      // return storyblok

      // API rewards
      return { offers: this.rewards, title: storyblok.title };
    },
    lastOrders() {
      return this.orderHistory.slice(0, 3);
    },
  },
  methods: {
    ...mapActions(["getOrderHistory"]),
    getContent(name) {
      return this.story?.content?.sections?.filter(
        (section) => section.component === name
      )[0];
    },
    goTo(path) {
      if (path) {
        this.$nuxt.$router.push(path);
      }
    },
  },
  head() {
    return this.$utils.head(this.story);
  },
  async asyncData({ $utils }) {
    return {
      story: await $utils.content("home"),
      banners: await $utils.content("_globals/banners"),
    };
  },
  async beforeMount() {
    console.log(this.story)
    if (this.user) {
      await Promise.all([this.getOrderHistory()]);
    }
  },
};
